import React, { forwardRef, useRef, useState } from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';

import { useQuery, useMutation } from '@apollo/react-hooks';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Info } from '@material-ui/icons';
import { parseGraphqlErrors } from '../../utils/FormikUtils';
import { Form } from '../Common/styled/Form';
import Loading from '../Common/Loading';
import { FIND_USER_BY_UUID, GET_HOSPITAL_GROUPS_FOR_EDITING, GET_MEDICAL_CASE_FOR_EDITING } from '../../graphql/queries';
import { GET_PROTOCOLS } from '../../queries/Protocols/Protocols';
import { CREATE_VIRTUAL_VISIT } from '../../queries/VirtualVisits/VirtualVisits';
import { INVITE_PATIENT } from '../../graphql/mutations';
import { DateTimeFieldUI } from '../../componentsUI/DateTimeField';
import { TextFieldSelectUI } from '../../componentsUI/TextFieldSelect';
import { getProtocolStateLabel } from '../../utils/ProtocolUtils';
import { TextSelectAutocompleteUI } from '../../componentsUI/TextSelectAutocomplete';
import { AlertUI } from '../../componentsUI/Alert';
import { PopoverIconUI } from '../../componentsUI/PopoverIconUI';
import { InnerSpanHTML } from '../Common/InnerHTML';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      padding: 15,
      width: 'calc(100% - 64px)',
      maxWidth: 600,
      '@media (max-width:600px)': {
        width: 'calc(100% - 16px)',
        padding: '15px 5px',
        margin: 8,
      },
    },
  },
  actions: {
    flexDirection: 'row',
    '& $button': {
      margin: '4px 8px',
      padding: '8px 25px',
    },
    '@media (min-width:601px)': {
      marginRight: -12,
    },
    '@media (max-width:600px)': {
      flexDirection: 'column',
      '& $button': {
        width: '100%',
      },
    },
  },
  button: {
    fontWeight: 700,
  },
  grid: {
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  protocol: {
    minHeight: 60,
    '& > .MuiGrid-item': {
      padding: '0 8px',
    },
  },
  description: {
    fontSize: '1.125em',
    fontWeight: 600,
  },
  uuid: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: '0.8em',
  },
  infoIcon: {
    marginTop: 9,
  },
  infoLink: {
    color: theme.palette.primary.main,
    padding: 0,
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'none',
    },
  },
  scope: {
    color: theme.palette.grey.A200,
    fontSize: '0.8em',
  },
  status: {
    minHeight: 45,
    marginBottom: 8,
  },
}));

export const VirtualVisitAddModal = ({ open, onClose, openShare, medicalCase, isInvited, setIsInvited }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const hospitalUuid = useSelector((state) => state.hospital.uuid);
  const userUuid = useSelector((state) => state.userInterface.user && state.userInterface.user.uuid);
  const [selectedProtocol, setSelectedProtocol] = useState(null);
  const [serverError, setServerError] = useState(false);
  const virtualVisitForm = useRef(null);
  const { loading: loadingProtocols, data: dataProtocols } = useQuery(GET_PROTOCOLS, { variables: { hospitalUuid } });
  const { loading: loadingDoctors, data: dataDoctors } = useQuery(GET_HOSPITAL_GROUPS_FOR_EDITING, { variables: { uuid: hospitalUuid } });
  const { loading: loadingUser, data: dataUser } = useQuery(FIND_USER_BY_UUID, { variables: { uuid: userUuid } });
  const today = moment.tz().format('YYYY-MM-DD');
  const language = i18n && i18n.language;

  const resetForm = () => {
    setServerError(null);
    setSelectedProtocol(null);
    virtualVisitForm.current.resetForm();
  };

  const [invitePatient, { loading: invitingPatient }] = useMutation(INVITE_PATIENT, {
    onCompleted() {
      toast(t('patient.has.received.invitation'), { className: 'toast-success' });
    },
    onError(error) {
      let warning = t('invitation.error');

      if (error.graphQLErrors && error.graphQLErrors.length) {
        warning = error.graphQLErrors[0].message;
      }

      if (error && error.message) {
        warning = error.message;
      }
      setServerError(warning);
    },
  });

  const [createVirtualVisit, { loading: creatingVisit }] = useMutation(CREATE_VIRTUAL_VISIT, {
    onCompleted() {
      toast(t('virtual.visit.created'), { className: 'toast-success' });
      resetForm();
      onClose();
    },
    onError(error) {
      const warning = error.graphQLErrors ? error.graphQLErrors[0].message : t('virtual.visit.error');
      setServerError(warning);
    },
    refetchQueries: [{ query: GET_MEDICAL_CASE_FOR_EDITING, variables: { uuid: medicalCase.uuid } }],
    awaitRefetchQueries: true,
  });

  const submitting = invitingPatient || creatingVisit;

  const closeModal = () => {
    if (!submitting) {
      resetForm();
      onClose();
    }
  };

  const openShareCase = () => {
    onClose();
    openShare();
  };

  let protocolOptions = [];
  let doctorOptions = [];

  const currentUser = dataUser && dataUser.userByUuid;
  const uniqueUsers = (users) => {
    const list = [];
    users.forEach((user) => {
      if (user && !list.find((elem) => elem && elem.uuid === user.uuid)) list.push(user);
    });
    return list;
  };

  const doctors = dataDoctors.hospital && currentUser && medicalCase && uniqueUsers([].concat(
    ...[currentUser],
    ...dataDoctors.hospital.teamMembers.map((member) => member.user).filter((user) => user.uuid === medicalCase.createdBy),
    ...medicalCase.collaborations.map((collaboration) => collaboration.user),
    ...dataDoctors.hospital.userGroups
      .filter((group) => medicalCase.groupCollaborations.map((gc) => gc.group.uuid).indexOf(group.uuid) !== -1)
      .map((group) => group.groupMembers.map((member) => member.user)),
  )).sort((a, b) => a.surname.localeCompare(b.surname));

  if (!loadingProtocols) {
    protocolOptions = dataProtocols.protocols.edges.map((protocol) => (
      { value: protocol.node.uuid, label: protocol.node.name }));
  }
  if (!loadingDoctors && !loadingUser && doctors) {
    doctorOptions = doctors.map((doctor) => (
      { value: doctor.uuid, label: `${doctor.name} ${doctor.surname}` }));
  }

  const validationSchema = Yup.object().shape({
    protocolUuid: Yup.string().required(t('required.field')),
    doctorUuid: Yup.string().required(t('required.field')),
    appointment: Yup.date().required(t('required.field')).min(today, t('appointment.date.after.today')),
  });

  const initialValues = {
    protocolUuid: '',
    doctorUuid: doctorOptions.length ? doctorOptions[0].value : '',
    appointment: protocolOptions.length ? moment().add(dataProtocols.protocols.edges[0].node.minDaysToVisit, 'd').format('YYYY-MM-DD') : '',
  };

  const validateProtocol = (props) => {
    // First, updating the appointment date default value based on the selected protocol attributes
    const uuid = props.values.protocolUuid && props.values.protocolUuid.value;
    const protocol = dataProtocols.protocols.edges.find((edge) => edge.node && edge.node.uuid === uuid);
    const date = protocol.node && moment().add(protocol.node.minDaysToVisit, 'd').format('YYYY-MM-DD');
    props.setFieldValue('appointment', date);

    // Then, set selected protocol to enable option to select and confirm date
    setSelectedProtocol(protocol.node);
  };

  const handleCreateVirtualVisit = (form) => {
    const values = { ...form, protocolUuid: form.protocolUuid.value };
    createVirtualVisit({
      variables: {
        input: {
          ...values,
          language,
          medicalCaseUuid: medicalCase.uuid,
          patientUuid: medicalCase.patient.uuid,
        },
      },
    }).then((r) => {
      if (r && r.data && r.data.createVirtualVisit) {
        onClose();
      }
    });
  };

  const handleInvitePatient = (callback, values) => {
    invitePatient({
      variables: {
        language,
        userEmail: medicalCase.patient.email,
        patientUuid: medicalCase.patient.uuid,
      },
    }).then((r) => {
      if (r && r.data && r.data.invitePatient) {
        setIsInvited(true);
        callback(values);
      }
    });
  };

  const handleSubmit = async (values, { setErrors }) => {
    setServerError(false);
    try {
      if (isInvited) {
        await handleCreateVirtualVisit(values);
      } else {
        await handleInvitePatient(handleCreateVirtualVisit, values);
      }
    } catch (errors) {
      const formikErrors = parseGraphqlErrors(errors.graphQLErrors, t);
      if (
        Object.keys(formikErrors).length === 0
        && formikErrors.constructor === Object
      ) {
        setServerError(true);
      } else {
        setErrors(formikErrors);
      }
    }
  };

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeModal}
    >
      <DialogTitle>
        <strong>{t('new.virtual.visit')}</strong>
        {!selectedProtocol && <span>{` - ${t('select.protocol')}`}</span>}
        {!!selectedProtocol && <span>{` - ${t('select.date')}`}</span>}
      </DialogTitle>
      <DialogContent>
        {(loadingProtocols || loadingDoctors) ? (
          <Loading />
        ) : (
          <>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              ref={virtualVisitForm}
            >
              {(props) => (
                <Form autoComplete="off" id="addVirtualVisit">
                  <Grid container spacing={2} className={classes.protocol}>
                    {!selectedProtocol && (
                      <Grid item xs={12}>
                        <TextSelectAutocompleteUI
                          name="protocolUuid"
                          label={t('protocol')}
                          props={props}
                          options={protocolOptions}
                        />
                      </Grid>
                    )}
                    {!!selectedProtocol && (
                      <Grid item xs={12}>
                        <Box className={classes.description}>{selectedProtocol.name}</Box>
                        <Box className={classes.uuid}>{selectedProtocol.uuid}</Box>
                        <Box className={classes.scope}>{t(getProtocolStateLabel(selectedProtocol.state))}</Box>
                      </Grid>
                    )}
                  </Grid>
                  <Grid container spacing={2} className={classes.grid}>
                    <Grid item xs={10} sm={6}>
                      <TextFieldSelectUI
                        name="doctorUuid"
                        label={t('doctor')}
                        props={props}
                        options={doctorOptions}
                      />
                    </Grid>
                    <Grid item xs={2} sm={6} className={classes.infoIcon}>
                      <PopoverIconUI
                        Icon={Info}
                        title={t('info')}
                        horizontalAO="center"
                        horizontalTO="center"
                        hide={!open}
                      >
                        <InnerSpanHTML content={t('virtual.visit.doctors.info')} />
                        <button type="button" onClick={openShareCase} className={classes.infoLink}>
                          {t('click.here.to.go')}
                        </button>
                      </PopoverIconUI>
                    </Grid>
                    {!!selectedProtocol && (
                      <Grid item xs={10} sm={6}>
                        <DateTimeFieldUI
                          future
                          name="appointment"
                          minDate={today}
                          iconPosition="right"
                          label={t('appointment')}
                          props={props}
                          classes={classes}
                        />
                      </Grid>
                    )}
                    <Grid item container xs={12} className={classes.status}>
                      {submitting && <Loading />}
                      {serverError && (
                        <AlertUI severity="error" title="Error">
                          {t('server.error')}
                        </AlertUI>
                      )}
                    </Grid>
                  </Grid>
                  <DialogActions className={classes.actions}>
                    {!selectedProtocol && (
                      <>
                        <Button
                          variant="outlined"
                          className={classes.button}
                          color="primary"
                          onClick={closeModal}
                        >
                          {t('cancel')}
                        </Button>
                        <Button
                          variant="contained"
                          className={classes.button}
                          color="primary"
                          form="addVirtualVisit"
                          disabled={!(props.values.protocolUuid && props.values.protocolUuid.value)}
                          onClick={() => validateProtocol(props)}
                        >
                          {t('accept')}
                        </Button>
                      </>
                    )}
                    {!!selectedProtocol && (
                      <>
                        <Button
                          hidden={selectedProtocol === 1}
                          variant="outlined"
                          className={classes.button}
                          color="primary"
                          onClick={() => setSelectedProtocol(null)}
                          disabled={submitting}
                        >
                          {t('change.protocol')}
                        </Button>
                        <Button
                          variant="contained"
                          className={classes.button}
                          color="primary"
                          form="addVirtualVisit"
                          type="submit"
                          disabled={submitting}
                        >
                          {t('schedule')}
                        </Button>
                      </>
                    )}
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
